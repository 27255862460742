<template>
    <div class="iq-card">
        <div class="card-header fertilizer-card-header">
            <div class="iq-header-title text-center">
                <h4 class="text-white">{{ $t('fertilizerConfig.fertililzer_import_status') }}</h4>
            </div>
            <div class="iq-card-header-toolbar d-flex align-items-center"></div>
        </div>
        <div class="card-body iq-card-body ">
            <div class="b-overlay-wrap position-relative">
                <div class="col-12">
                    <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
                        <b-form  @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" >
                            <b-row>
                                <b-col lg="4" md="4" sm="12">
                                  <ValidationProvider name="Year" vid="year">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="3"
                                        label-for="year"
                                        slot-scope="{ valid, errors }"
                                        >
                                        <template v-slot:label>
                                        {{ $t('crop_price_config.year') }}
                                        </template>
                                        <b-form-select
                                        plain
                                        v-model="search.year"
                                        :options="yearList"
                                        id="year"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        @change="loadData()"
                                        >
                                        <template v-slot:first>
                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                        </template>
                                        </b-form-select>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                  </ValidationProvider>
                                </b-col>
                                <b-col lg="4" sm="12" class="text-center">
                                    <ValidationProvider name="Month"  vid="month_id" rules="required">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="5"
                                            label-for="month_id"
                                            slot-scope="{ valid, errors }"
                                        >
                                        <template v-slot:label>
                                            {{ $t('globalTrans.month') }}
                                        </template>
                                        <b-form-select
                                            plain
                                            v-model="search.month_id"
                                            :options="monthList"
                                            id="region_id"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            @change="loadData()"
                                            >
                                            <template v-slot:first>
                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-select>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col lg="4" sm="12" class="text-center">
                                    <ValidationProvider name="Fertilizer"  vid="fertilizer_id"  rules="required|min_value:1">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="5"
                                            label-for="fertilizer_id"
                                            slot-scope="{ valid, errors }"
                                        >
                                        <template v-slot:label>
                                            {{ $t('fertilizerConfig.fertilizer_name') }}
                                        </template>
                                        <b-form-select
                                            plain
                                            v-model="search.fertilizer_id"
                                            :options="fertilizerNameList"
                                            id="fertilizer_id"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            @change="loadData()"
                                            >
                                            <template v-slot:first>
                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                            </b-form-select>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                            </b-row>
                        </b-form>
                    </ValidationObserver>
                </div>
                <div class="col-12">
                   <hr class="hrclass"/>
                    <b-overlay :show="loading">
                      <div class="row">
                        <div class="col-5">
                            <div class="seeds-box" style="height: 330px">
                                <h5 class="card-title text-center text-bold" style="color: #424242 !important;">{{ $t('fertilizerConfig.country_wise_fertililzer_status') }}</h5>
                                <apexchart class="chart-1 text-center px-5"  v-if="searchThis" type="pie" height="297" :options="spaceAvailableDashboardLabels" :series="series()"></apexchart>
                            </div>
                        </div>
                        <div class="col-7">
                            <div class="seeds-box" style="height: 330px">
                                <h5 class="card-title text-center text-bold" style="color: #424242 !important;">{{ $t('fertilizerConfig.monthly_fertililzer_import_status') }}</h5>
                                <apexchart class="chart-1 text-center px-5" id="vuechart-example" v-if="searchThis" type="bar" height="250" :options="dataOption" :series="seriesBar"></apexchart>
                            </div>
                        </div>
                      </div>
                    </b-overlay>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
.fertilizer-card-header{
  color: #fff;
  text-align: center;
  padding: 5px!important;
  background-color: var(--brand-secondary-color)!important;
}
.hrclass {
    border-top: 1px solid var(--brand-color) !important;
    margin-top: 5px !important;
    margin-bottom: 5px !important;
}
.seeds-box {
    border-radius: 3px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    padding: 10px 25px;
    text-align: right;
    display: block;
    margin-top: 20px;
}
.seeds-box-icon {
    background-color: #609513 !important;
    display: table;
    height: 30px;
    margin: 0 auto;
    width: 70%;
    margin-top: -20px;
    font-size: 12px !important;
}
.seeds-box-icon span {
    color: #fff;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
}
.info h4 {
    font-size: 26px;
    letter-spacing: 2px;
    text-transform: uppercase;
}
.info > p {
    color: #717171;
    font-size: 16px;
    padding-top: 10px;
    text-align: justify;
}
</style>
<script>
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { importStatus } from '../../api/routes'
import moment from 'moment'

export default {
  name: 'UiDataTable',
  data () {
    return {
      datas: [],
      monthlyWiseImport: [],
      search: {
        month_id: 0,
        year: 0,
        fertilizer_id: 1
      },
      seriesBar: [{
        name: 'Total:',
        data: []
      }],
      searchThis: false,
      loading: false,
      seriesData: [0, 0, 0],
      chartOptions: {
        chart: {
          width: 200,
          type: 'pie'
        },
        legend: {
          position: 'bottom'
        },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      },
      firstLabel: [],
      firstLabelBn: []
    }
  },
  computed: {
     yearList: function () {
      var max = new Date().getFullYear()
      var min = max - 31
      var years = []
      for (var i = max; i >= min; i--) {
        const yearData = {}
        yearData.value = i
        yearData.text = this.$i18n.locale === 'bn' ? this.$n(i, { useGrouping: false }) : i
        yearData.text_en = i
        yearData.text_bn = i
        years.push(yearData)
      }
      return years
      // return this.$store.state.SeedsFertilizer.commonObj.yearList
    },
    dataOption () {
      return {
        chart: {
          id: 'vuechart-example',
          type: 'bar'
        },
        plotOptions: {
          bar: {
            barHeight: '50px',
            columnWidth: '40%',
            distributed: true,
            horizontal: false,
            dataLabels: {
              position: 'bottom',
              horizontalAlign: 'center'
            }
          }
        },
        labels: [
          this.$t('months.jul'),
          this.$t('months.aug'),
          this.$t('months.sep'),
          this.$t('months.oct'),
          this.$t('months.nov'),
          this.$t('months.dec'),
          this.$t('months.jan'),
          this.$t('months.feb'),
          this.$t('months.mar'),
          this.$t('months.apr'),
          this.$t('months.may'),
          this.$t('months.jun')
        ]
      }
    },
    spaceAvailableDashboardLabels () {
      return {
        chart: {
          width: 200,
          type: 'pie'
        },
        labels: this.$i18n.locale === 'bn' ? this.firstLabelBn : this.firstLabel,
        legend: {
          position: 'bottom'
        },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      }
    },
    regionList: function () {
        return this.$store.state.commonObj.officeList.filter(item => item.status === 0 && item.office_type_id === 72)
    },
    fertilizerNameList: function () {
        return this.$store.state.SeedsFertilizer.commonObj.fertilizerNameList.filter(item => item.status === 1)
    },
    SalesCenterList: function () {
       return this.$store.state.commonObj.officeList.filter(item => item.office_type_id === 73)
    },
    monthList: function () {
      return this.$store.state.commonObj.monthList
    }
  },
  async created () {
    this.loadData()
  },
  methods: {
    monthCheck (value) {
      if (this.$i18n.locale === 'bn') {
        // var bengali = ['০', '১', '২', '৩', '৪', '৫', '৬', '৭', '৮', '৯']
        // return bengali.indexOf(parseInt(moment(value).format('M')))
         return moment(value).format('M')
      } else {
        return moment(value).format('M')
      }
    },
    series () {
      return this.seriesData
    },
    async loadData () {
      if (this.search.fertilizer_id) {
        this.loading = true
        await RestApi.getData(seedFertilizerServiceBaseUrl, importStatus, this.search).then(response => {
          if (response.success) {
            this.datas = response.data
            this.monthlyWiseImportFn(response.data.monthlyWiseImport)
            const seriesData = []
            this.firstLabel = []
            this.firstLabelBn = []
            if (this.datas.countryWiseImport.length > 0) {
              this.datas.countryWiseImport.forEach((data, key) => {
                seriesData.push(data.pecentage)
                const countryList = this.$store.state.SeedsFertilizer.commonObj.fertilizerImportCountryList.find(item => item.value === data.country_id)
                const fartilizerObj = this.fertilizerNameList.find(item => item.value === data.fertilizer_id)
                const fertilizerName = typeof fartilizerObj !== 'undefined' ? fartilizerObj.text_en : ''
                const fertilizerNamebn = typeof fartilizerObj !== 'undefined' ? fartilizerObj.text_bn : ''
                this.firstLabel.push(typeof countryList !== 'undefined' ? countryList.text_en + '(' + fertilizerName + ')' : '')
                this.firstLabelBn.push(typeof countryList !== 'undefined' ? countryList.text_bn + '(' + fertilizerNamebn + ')' : '')
              })
              this.seriesData = seriesData
              this.searchThis = true
            } else {
              this.seriesData = ['']
              this.firstLabel = ['']
              this.firstLabelBn = [100]
            }
          }
          this.loading = false
        })
      }
    },
    monthlyWiseImportFn (data) {
      const prevData = data.map(item => {
        const monthData = this.monthCheck(item.purchase_date)
        const monthAssignData = {
          month_id: parseInt(monthData)
        }
        return Object.assign({}, item, monthAssignData)
      })
      const dataCh = []
      this.monthList.forEach((data, key) => {
        const monthInfo = prevData.find(item1 => item1.month_id === parseInt(data.value))
        if (typeof monthInfo !== 'undefined') {
          dataCh.push(monthInfo.quantity)
        } else {
          dataCh.push(0)
        }
      })
      this.seriesBar = [{
        name: 'Total:',
        data: dataCh
      }]
    }
  }
}
</script>
