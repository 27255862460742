<template>
    <div class="iq-card">
        <div class="card-header fertilizer-card-header">
            <div class="iq-header-title text-center">
                <h4 class="text-white"><b>{{ $t('fertilizerConfig.fertililzer_price_&_Region_Current_Status') }}</b></h4>
            </div>
            <div class="iq-card-header-toolbar d-flex align-items-center"></div>
        </div>
        <div class="card-body iq-card-body ">
            <div class="b-overlay-wrap position-relative">
                <div class="row">
                    <div class="col-5">
                        <div style="border: 2px solid">
                            <h4 class="bg-success text-center"><b>{{$t('fertilizerConfig.fertilizerPrice.fertilizerPrice')}}</b></h4>
                            <table class="table table-sm" v-if="datas.length > 0">
                                <thead>
                                    <tr>
                                        <th scope="col" class="text-center">{{$t('globalTrans.sl_no')}}</th>
                                        <th scope="col" class="text-center">{{$t('fertilizerConfig.fertilizer_name')}}</th>
                                        <th scope="col" class="text-center">{{$t('fertilizerConfig.fertilizerPrice.dealerPrice')}}</th>
                                        <th scope="col" class="text-center">{{$t('fertilizerConfig.fertilizerPrice.farmerPrice')}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(list, index) in datas" :key="index">
                                        <th scope="row" class="text-center">{{$n(index+1)}}</th>
                                        <td class="text-center">
                                            {{ $i18n.locale === 'en' ? list.fertilizer.fertilizer_name : list.fertilizer.fertilizer_name_bn }}
                                            <!-- {{list.fertilizer.fertilizer_name}} -->
                                        </td>
                                        <td class="text-center">{{$n(list.dealer_price, { useGrouping: false })}}</td>
                                        <td class="text-center">{{$n(list.farmer_price, { useGrouping: false })}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="col-7">
                        <b-overlay :show="loading">
                            <div style="border: 2px solid">
                                <h4 class="bg-success text-center"><b>{{$t('fertilizerConfig.current_stock')}} : {{ $t('fertilizerReport.accountMayTons') }}</b></h4>
                                <b-table-simple bordered hover small caption-top responsive v-if="stockDataRegionWiseTotalStock.length > 0">
                                    <b-thead>
                                        <b-tr>
                                            <b-th style="width:7%" class="text-center">{{ $t('globalTrans.sl_no') }}</b-th>
                                            <b-th style="width:30%" class="text-center">{{ $t('fertilizerConfig.regional_office') }}</b-th>
                                            <b-th style="width:10%" class="text-center" v-for="(fertilizer, index) in fertilizerNameList" :key="index">{{ currentLocale === 'en' ? fertilizer.text_en : fertilizer.text_bn }}</b-th>
                                            <b-th style="width:10%" class="text-center">{{ $t('fertilizerReport.total') }}</b-th>
                                        </b-tr>
                                    </b-thead>
                                    <b-tbody>
                                        <b-tr v-for="(stockDataItem, stockDataIndex) in stockDataRegionWiseTotalStock" :key="stockDataIndex">
                                        <b-td class="text-center">
                                            {{ $n(stockDataIndex + 1) }}
                                        </b-td>
                                        <b-td class="text-center">
                                            {{ currentLocale === 'en' ? stockDataItem.region_name : stockDataItem.region_name_bn }}
                                        </b-td>
                                        <b-td class="text-center" v-for="(fertilizerName, fertilizerIndex) in fertilizerNameList" :key="fertilizerIndex" >
                                            {{ $n(stockDataItem.region_total_obj[fertilizerName.value]) }}
                                        </b-td>
                                        <b-td class="text-center">{{ $n(sum(stockDataItem.region_total_obj)) }}</b-td>
                                        </b-tr>
                                        <b-tr>
                                        <b-th colspan="2" class="text-right">
                                            {{ $t('globalTrans.total') }}
                                        </b-th>
                                        <b-th   v-for="(fertilizerName, fertilizerIndex) in fertilizerNameList" :key="fertilizerIndex" class="text-center">
                                            {{ $n(regionWiseTotalStockTotalObj[fertilizerName.value]) }}
                                        </b-th>
                                        <b-th class="text-center">{{ $n(sum(regionWiseTotalStockTotalObj)) }}</b-th>
                                        </b-tr>
                                        <b-tr>
                                        <b-th colspan="2" class="text-right">
                                            {{ $t('fertilizerReport.transported') }}
                                        </b-th>
                                        <b-th v-for="(fertilizerName, fertilizerIndex) in fertilizerNameList" :key="fertilizerIndex" class="text-center">
                                            {{ $n(totalTranstportedInfoTotalObj[fertilizerName.value]) }}
                                        </b-th>
                                        <b-th class="text-center">{{ $n(sum(totalTranstportedInfoTotalObj)) }}</b-th>
                                        </b-tr>
                                    </b-tbody>
                                    <b-tfoot>
                                        <b-tr>
                                            <b-th colspan="2" class="text-right">
                                              {{ $t('fertilizerConfig.total') }}
                                            </b-th>
                                            <b-th v-for="(fertilizerName, fertilizerIndex) in fertilizerNameList" :key="fertilizerIndex" class="text-center">
                                              {{ $n(regionWiseTotalStockGrandTotalObj[fertilizerName.value]) }}
                                            </b-th>
                                            <b-th class="text-center">{{ $n(sum(regionWiseTotalStockGrandTotalObj)) }}</b-th>
                                        </b-tr>
                                    </b-tfoot>
                                </b-table-simple>
                            </div>
                        </b-overlay>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
.fertilizer-card-header{
  color: #fff;
  text-align: center;
  padding: 5px!important;
  background-color: var(--brand-secondary-color)!important;
}
.hrclass {
    border-top: 1px solid var(--brand-color)!important;
    margin-top: 5px !important;
    margin-bottom: 5px !important;
}
.seeds-box {
    border-radius: 3px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    padding: 10px 25px;
    text-align: right;
    display: block;
    margin-top: 20px;
}
.seeds-box-icon {
    background-color: #609513 !important;
    display: table;
    height: 30px;
    margin: 0 auto;
    width: 70%;
    margin-top: -20px;
    font-size: 12px !important;
}
.seeds-box-icon span {
    color: #fff;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
}
.info h4 {
    font-size: 26px;
    letter-spacing: 2px;
    text-transform: uppercase;
}
.info > p {
    color: #717171;
    font-size: 16px;
    padding-top: 10px;
    text-align: justify;
}
</style>
<script>
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { priceInfo, currentStockReport } from '../../api/routes'

export default {
  name: 'UiDataTable',
  data () {
    return {
      search: {
        region_id: 0,
        sales_center_id: 0,
        report_type: 3,
        fertilizer_id: 0
      },
      loading: false,
      datas: [],
      totalStockReportTotalObj: {},
      totalStockReportKeys: [],
      totalTranstportedInfoTotalObj: {},
      godownWiseTotalStockTotalObj: {},
      godownWiseTotalStockGrandTotalObj: {},
      regionWiseTotalStockTotalObj: {},
      regionWiseTotalStockGrandTotalObj: {},
      stockData: [],
      stockDataTotalStock: [],
      stockDataGodownWiseTotalStock: [],
      stockDataRegionWiseTotalStock: []
    }
  },
  computed: {
    currentLocale () {
      return this.$i18n.locale
    },
    regionList: function () {
        return this.$store.state.commonObj.officeList.filter(item => item.office_type_id === 72)
    },
    fertilizerNameList: function () {
        return this.$store.state.SeedsFertilizer.commonObj.fertilizerNameList.filter(item => item.status === 1)
    },
    SalesCenterList: function () {
       return this.$store.state.commonObj.officeList.filter(item => item.office_type_id === 73)
    }
  },
  async created () {
    this.loadData()
    this.loadDataStock()
  },
  methods: {
    sum (obj) {
      var sum = 0
      for (var el in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, el)) {
          sum += parseFloat(obj[el])
        }
      }
      return sum
    },
    gomycell (key) {
      return `cell(${key})`
    },
    getFertilizerAmount (fertilizerData, fertilizerId) {
      const fertilizerDataItem = fertilizerData.find(fertilizer => fertilizer.fertilizer_id === fertilizerId)
      return typeof fertilizerDataItem !== 'undefined' ? this.$n(fertilizerDataItem.amount) : ''
    },
    series () {
      return this.seriesData
    },
    async loadDataStock () {
      this.loading = true
      const today = new Date()
      const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
      this.currentDate = date
      const params = Object.assign({}, this.search)
      await RestApi.getData(seedFertilizerServiceBaseUrl, currentStockReport, params).then(response => {
        if (response.success) {
          const result = response.current_stock_data
          const transportData = response.transported_stock_data

          if (this.search.report_type >= 2) {
            this.fertilizerNameList.filter(item => {
              if (typeof this.totalTranstportedInfoTotalObj[item.value] === 'undefined') {
                this.totalTranstportedInfoTotalObj[item.value] = 0
                this.godownWiseTotalStockGrandTotalObj[item.value] = 0
                this.regionWiseTotalStockGrandTotalObj[item.value] = 0
              }
              if (transportData.length) {
                transportData.filter(resultItem => {
                  if (item.value === resultItem.fertilizer_id) {
                    this.totalTranstportedInfoTotalObj[item.value] += resultItem.amount
                    this.godownWiseTotalStockGrandTotalObj[item.value] += resultItem.amount
                    this.regionWiseTotalStockGrandTotalObj[item.value] += resultItem.amount
                  }
                })
              }
            })
          }

          if (this.search.report_type === 3) {
            this.stockData = result
            const reportData = []
            if (result.length) {
              result.filter(item => {
                let regionItemObj = reportData.find(regionItem => regionItem.region_id === item.region_id)
                if (typeof regionItemObj === 'undefined') {
                  const region = this.regionList.find(region => region.value === item.region_id)
                  let regionName, regionNameBn
                  if (typeof region !== 'undefined') {
                    regionName = region.text_en
                    regionNameBn = region.text_bn
                  } else {
                    regionName = ''
                    regionNameBn = ''
                  }
                  regionItemObj = {
                    region_id: item.region_id,
                    region_name: regionName,
                    region_name_bn: regionNameBn,
                    region_data: []
                  }
                }
                const fertilizerItem = {}
                fertilizerItem.fertilizer_id = item.id
                fertilizerItem.amount = item.amount
                regionItemObj.region_data.push(fertilizerItem)
                regionItemObj.region_total_obj = {}
                this.fertilizerNameList.filter(fertilizerName => {
                  let totalAmount = 0
                  const fertilizerObj = regionItemObj.region_data.find(fertilizerObj => fertilizerObj.fertilizer_id === fertilizerName.value)
                  if (typeof fertilizerObj !== 'undefined') {
                    totalAmount += fertilizerObj.amount
                  }
                  regionItemObj.region_total_obj[fertilizerName.value] = totalAmount
                })
                const regionItemIndex = reportData.findIndex(reportDataItem => reportDataItem.region_id === item.region_id)
                if (regionItemIndex !== -1) {
                  reportData.splice(regionItemIndex, 1)
                }
                reportData.push(regionItemObj)
              })
              this.stockData = reportData
              this.stockDataRegionWiseTotalStock = reportData
              this.fertilizerNameList.filter(fertilizerName => {
                let totalAmount = 0
                this.stockData.filter(regionItem => {
                  const fertilizer = regionItem.region_data.find(fertilizerItem => fertilizerItem.fertilizer_id === fertilizerName.value)
                  if (typeof fertilizer !== 'undefined') {
                    totalAmount += fertilizer.amount
                  }
                })
                this.regionWiseTotalStockGrandTotalObj[fertilizerName.value] += totalAmount
                this.regionWiseTotalStockTotalObj[fertilizerName.value] = totalAmount
              })
            }
          }
          this.loading = false
        }
      })
    },
    loadData () {
        RestApi.getData(seedFertilizerServiceBaseUrl, priceInfo, []).then(response => {
            if (response.success) {
                this.datas = response.data
            }
        })
    }
  }
}
</script>
