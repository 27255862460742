<template>
    <b-container fluid>
        <b-row>
            <div class="col-md-12">
                <SalesInfo/>
                <FertililzerPrice/>
                <FertililzerImport/>
            </div>
        </b-row>
    </b-container>
</template>
<style scoped>
.hrclass {
    border-top: 1px solid rgb(96, 149, 19) !important;
    margin-top: 5px !important;
    margin-bottom: 5px !important;
}
.seeds-box {
    border-radius: 3px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    padding: 10px 25px;
    text-align: right;
    display: block;
    margin-top: 20px;
}
.seeds-box-icon {
    background-color: #609513 !important;
    display: table;
    height: 30px;
    margin: 0 auto;
    width: 70%;
    margin-top: -20px;
    font-size: 12px !important;
}
.seeds-box-icon span {
    color: #fff;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
}
.info h4 {
    font-size: 26px;
    letter-spacing: 2px;
    text-transform: uppercase;
}
.info > p {
    color: #717171;
    font-size: 16px;
    padding-top: 10px;
    text-align: justify;
}
</style>
<script>
import SalesInfo from './SalesInfo'
import FertililzerImport from './FertililzerImport'
import FertililzerPrice from './FertililzerPrice'

export default {
  name: 'UiDataTable',
  components: {
    SalesInfo,
    FertililzerImport,
    FertililzerPrice
  },
  data () {
    return {
      search: {
        region_id: 0,
        sales_center_id: 0,
        fertilizer_id: 0
      },
      seriesBar: [{
        name: 'Total:',
        data: [45, 70, 49, 60, 45, 70, 49, 120, 45, 70, 49, 160]
      }],
      seriesData: [30, 40, 30],
      chartOptions: {
        chart: {
          width: 200,
          type: 'pie'
        },
        labels: ['Total Space', 'Actual Space', 'Free Space'],
        colors: ['#548235', '#0070C0', '#FF0000', '#7030A0'],
        legend: {
          position: 'bottom'
        },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      }
    }
  },
  computed: {
    dataOption () {
      return {
        chart: {
          id: 'vuechart-example',
          type: 'bar'
        },
        plotOptions: {
          bar: {
            barHeight: '50px',
            columnWidth: '40%',
            distributed: true,
            horizontal: false,
            dataLabels: {
              position: 'bottom',
              horizontalAlign: 'center'
            }
          }
        },
        labels: [
          this.$t('months.jul'),
          this.$t('months.aug'),
          this.$t('months.sep'),
          this.$t('months.oct'),
          this.$t('months.nov'),
          this.$t('months.dec'),
          this.$t('months.jan'),
          this.$t('months.feb'),
          this.$t('months.mar'),
          this.$t('months.apr'),
          this.$t('months.may'),
          this.$t('months.jun')
        ],
        colors: ['#666666', '#3333cc', '#ff0000', '#009900', '#666666', '#3333cc', '#ff0000', '#009900', '#666666', '#3333cc', '#ff0000', '#009900']
      }
    },
    spaceAvailableDashboardLabels () {
      return {
        chart: {
          width: 200,
          type: 'pie'
        },
        labels: [this.$t('warehouse_service.totalSpace'), this.$t('warehouse_service.actualSpace'), this.$t('warehouse_service.filledSpace')],
        colors: ['#548235', '#0070C0', '#7030A0', '#FF0000'],
        legend: {
          position: 'bottom'
        },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      }
    },
    regionList: function () {
        return this.$store.state.commonObj.officeList.filter(item => item.status === 0 && item.office_type_id === 72)
    },
    fertilizerNameList: function () {
        return this.$store.state.SeedsFertilizer.commonObj.fertilizerNameList.filter(item => item.status === 1)
    },
    SalesCenterList: function () {
       return this.$store.state.commonObj.officeList.filter(item => item.office_type_id === 73)
    }
  },
  methods: {
    series () {
      return this.seriesData
    }
  }
}
</script>
