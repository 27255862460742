<template>
     <div class="iq-card">
        <div class="card-header fertilizer-card-header">
            <div class="iq-header-title text-center">
                <h4 class="text-white">{{ $t('fertilizerConfig.sales_info') }}</h4>
            </div>
        </div>
        <div class="card-body iq-card-body ">
            <div class="b-overlay-wrap position-relative">
                <div class="row">
                    <div class="col-12">
                        <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
                            <b-form  @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" >
                                <b-row>
                                    <b-col lg="4" sm="12" class="text-center">
                                        <ValidationProvider name="Region"  vid="region_id" rules="required">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="5"
                                                label-for="region_id"
                                                slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{ $t('globalTrans.region') }}
                                            </template>
                                            <b-form-select
                                                plain
                                                v-model="search.region_id"
                                                :options="regionList"
                                                id="region_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                @change="loadData()"
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                                </b-form-select>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col lg="4" sm="12" class="align-content-center">
                                        <ValidationProvider name="Sales Center"  vid="sales_center_id">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="5"
                                                label-for="sales_center_id"
                                                slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{$t('seedFer.sales_center')}}
                                            </template>
                                            <b-form-select
                                                plain
                                                v-model="search.sales_center_id"
                                                :options="SalesCenterList"
                                                id="sales_center_id"
                                                label-class="text-right"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                @change="loadData()"
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col lg="4" sm="12" class="text-center">
                                        <ValidationProvider name="Fertilizer"  vid="fertilizer_id" >
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="5"
                                                label-for="fertilizer_id"
                                                slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{ $t('fertilizerConfig.fertilizer_name') }}
                                            </template>
                                            <b-form-select
                                                plain
                                                v-model="search.fertilizer_id"
                                                :options="fertilizerNameList"
                                                id="fertilizer_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                @change="loadData()"
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                                </b-form-select>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                </b-row>
                            </b-form>
                        </ValidationObserver>
                    </div>
                    <div class="col-12">
                        <hr class="hrclass"/>
                        <b-overlay :show="loading">
                            <div class="row" aria-hidden="loading | listReload ? 'true' : null" >
                                <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                    <div class="seeds-box">
                                        <div class="seeds-box-icon">
                                            <h4 class="text-center text-white">{{ $t('fertilizerConfig.today_sales') }}</h4>
                                        </div>
                                        <div class="info text-center">
                                            <!-- <h2>100 M Ton</h2> -->
                                            <div class="d-inline-flex p-2"><h2 class="text-dark">{{$n(todaySales, { useGrouping: false })}} {{ $t('fertilizerReport.MTon') }}</h2></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                    <div class="seeds-box">
                                        <div class="seeds-box-icon">
                                            <h4 class="text-center text-white">{{ $t('fertilizerConfig.today_delivery') }}</h4>
                                        </div>
                                        <div class="info text-center">
                                            <!-- <h2>100 M Ton</h2> -->
                                            <div class="d-inline-flex p-2 text-warning"><h2 class="text-dark">{{$n(todayDelivery, { useGrouping: false })}} {{ $t('fertilizerReport.MTon') }}</h2></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                    <div class="seeds-box">
                                        <div class="seeds-box-icon">
                                            <h4 class="text-center text-white">{{ $t('fertilizerConfig.current_stock') }}</h4>
                                        </div>
                                        <div class="info text-center">
                                            <!-- <h2>100 M Ton</h2> -->
                                            <div class="d-inline-flex p-2 text-warning text-success"><h2 class="text-dark">{{$n(currentStock, { useGrouping: false })}} {{ $t('fertilizerReport.MTon') }}</h2></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </b-overlay>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
.fertilizer-card-header{
  color: #fff;
  text-align: center;
  padding: 5px!important;
  background-color: var(--brand-secondary-color)!important;
}
.hrclass {
    border-top: 1px solid var(--brand-color) !important;
    margin-top: 5px !important;
    margin-bottom: 5px !important;
}
.seeds-box {
    border-radius: 3px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    padding: 10px 0px;
    text-align: right;
    display: block;
    background-color: #a0ffa0;
    margin-top: 20px;
}
.seeds-box-icon {
    margin: 0 auto;
    margin-top: -20px;
    font-size: 12px !important;
    height: 40px;
    padding-top: 7px;
    background-color: var(--brand-secondary-color);
}
.seeds-box-icon span {
    color: #fff;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
}
.info h4 {
    font-size: 26px;
    letter-spacing: 2px;
    text-transform: uppercase;
}
.info > p {
    color: #717171;
    font-size: 16px;
    padding-top: 10px;
    text-align: justify;
}
</style>
<script>
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { salesInfo } from '../../api/routes'

export default {
  name: 'UiDataTable',
  data () {
    return {
      search: {
        region_id: 0,
        sales_center_id: 0,
        fertilizer_id: 0
      },
      loading: false,
      todaySales: 0,
      todayDelivery: 0,
      currentStock: 0
    }
  },
  computed: {
    regionList: function () {
        return this.$store.state.commonObj.officeList.filter(item => item.status === 0 && item.office_type_id === 72)
    },
    fertilizerNameList: function () {
        return this.$store.state.SeedsFertilizer.commonObj.fertilizerNameList.filter(item => item.status === 1)
    },
    SalesCenterList: function () {
       return this.$store.state.commonObj.officeList.filter(item => item.office_type_id === 73)
    }
  },
  async created () {
    this.loadData()
  },
  methods: {
    series () {
      return this.seriesData
    },
    loadData () {
        this.loading = true
        RestApi.getData(seedFertilizerServiceBaseUrl, salesInfo, this.search).then(response => {
            if (response.success) {
                this.todaySales = response.data.todaySales
                this.todayDelivery = response.data.todayDelivery
                this.currentStock = response.data.currentStock
            }
            this.loading = false
        })
    }
  }
}
</script>
